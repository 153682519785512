import './appInit';

import { Fragment, useCallback, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { observer } from 'decorators';
import { makeStyles } from 'hooks';

import globalEvents from 'services/globalEvents';
import api from 'services/api';
import auth from 'services/auth';
import config from 'config';
import theme from 'theme';
import AppView from './app';

import { installScript } from 'utils/dom';
import { splitWindowQuery } from 'utils/query';
import { startAppInsights } from './appInsights';

import { Paper, Typography } from '@material-ui/core';
import AsyncView from 'components/asyncView';
import Modal from 'components/modal';
import NavLink from 'components/navLink';
import Image from 'components/image';
import media from 'services/media';
import router from 'services/router';

import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { Helmet } from 'react-helmet-async';
import { Alert, CheckCircleOutline } from 'mdi-material-ui';

const FullAppView = () => import('layout/fullApp');

const useStyles = makeStyles(theme => ({
  '@global': {
    '#root': {
      height: '100%',
      position: 'relative'
    }
  }
}));

export const useSuccessBoxStyles = makeStyles(theme => ({
  logo: {
    display: 'block',
    width: '50%',
    margin: '0 auto',
    marginBottom: theme.spacing(4)
  },
  container: {
    padding: theme.spacing(4, 2),
    textAlign: 'center'
  },
  iconImage: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    color: theme.palette.grey[700],
    paddingBottom: theme.spacing(2)
  },
  error: {
    color: theme.palette.error.main
  }
}));

export const SuccessBox = observer(function SuccessBox({ children, showLogo, orgLogo, orgId, icon, hideFallback }) {
  const [ loaded, setLoaded ] = useState(false);
  const classes = useSuccessBoxStyles();

  const url = (orgLogo ? auth.user?.website : null) || (hideFallback ? null : theme.brand.frontPageUrl);
  const orgName = (orgLogo ? auth.user?.orgPublicName : null) || (hideFallback ? null : theme.brand.name);
  orgId = orgId || (orgLogo ? (auth.user?.orgId || splitWindowQuery().query.orgHint) : null);

  const handleLoad = useCallback(() => setLoaded(true), []);
  let image = showLogo && <Image
    src={orgId ? `${config.anon_path}/org/${orgId}/logo` : undefined}
    fallbackSrc={hideFallback ? undefined : theme.brand.logoUrl}
    className={classes.logo}
    alt={orgName}
    onLoad={handleLoad}
  />;

  if (image && url) {
    image = <NavLink href={url}>
      { image }
    </NavLink>;
  }

  return <Modal open hideBackdrop width={400} PaperComponent="div">
    { image &&
      <div style={{ display: loaded ? undefined : 'none' }}>
        { image }
      </div>
    }
    <Paper className={classes.container} elevation={2}>
      { icon || <CheckCircleOutline className={classes.iconImage} /> }
      { children }
    </Paper>
  </Modal>;
});

const MainView = observer(function MainView() {
  useStyles();
  const successClasses = useSuccessBoxStyles();

  if (auth.globalBlockingError) {
    const { title, desc } = auth.globalBlockingError;
    return <SuccessBox showLogo icon={<Alert className={successClasses.iconImage} />}>
      <Typography color="primary" variant="h6" paragraph>{title}</Typography>
      <Typography paragraph>{desc}</Typography>
    </SuccessBox>;
  }

  if (!router.isInit) { return null; }

  const route = router.currentRoute;
  const isExternal = route ? route.external : false;
  const isAnonymous = route ? route.anonymous : false;

  // If we are on an external page, without access token, and we need to auth
  // better head back to the main app
  if (isExternal && !isAnonymous && !auth.attempting && !auth.isAuthed && !auth.hasFixedToken) {
    auth.retry(false, false).done();
  }

  const dragDropBackend = media.isTouchDevice ? TouchBackend : HTML5Backend;
  const dragDropOpts = media.isTouchDevice ? { ignoreContextMenu: true, touchSlop: -1, enableHoverOutsideTarget: true } : undefined;

  const view = (isExternal && (isAnonymous || auth.isAuthed || auth.hasFixedToken)) ? route.view : FullAppView;
  const title = router.currentNavRoute?.title;
  return <DndProvider backend={dragDropBackend} options={dragDropOpts}>
    { title &&
      <Helmet>
        <title>{title}</title>
      </Helmet>
    }
    <AsyncView view={view} routeData={router} />
  </DndProvider>;
});

const app = <Fragment>
  <AppView>
    <MainView />
  </AppView>
  { /* Global audio div (used in telehealth) */ }
  <div id="global-audio" style={{ display: 'none' }} />
  { /* Global div for fb events */ }
  <div id="global-fb-events" style={{ display: 'none' }} />
  { /* Global div for jitsi telehealth */ }
  <div id="jaas-overlay" style={{ display: 'none', zIndex: 1299, position: 'fixed' }}>
    <div id="jaas-container" style={{ width: '100%', height: '100%' }} />
    <div id="jaas-chat" />
  </div>
</Fragment>;

// Start the app
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(app);

// Azure App Insights
startAppInsights('WebClient');

// Connect to websockets and watch for events
globalEvents.startEvents(api);

// Load post affiliate pro and track
installScript('https://affiliate.kalixhealth.com/scripts/qjl956', false, 'pap_x2s6df8d')
  .then(() => {
    window.PostAffTracker.setAccountId('default1');
    window.PostAffTracker.track();
  })
  .done();

// Partnero tracking
/* eslint-disable */
try {
  (function(p,t,n,e,r,o){ p['__partnerObject']=r;function f(){
  var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
  f.q=f.q||[];p[r]=p[r]||f.bind(f.q);p[r].q=p[r].q||f.q;o=t.createElement(n);
  var _=t.getElementsByTagName(n)[0];o.async=1;o.src=e+'?v'+(~~(new Date().getTime()/1e6));
  _.parentNode.insertBefore(o,_);})(window, document, 'script', 'https://app.partnero.com/js/universal.js', 'po');
  po('settings', 'assets_host', 'https://assets.partnero.com');
  po('program', '0LYKCBFD', 'load');
} catch {}
/* eslint-enable */